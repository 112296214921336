import lottie from 'lottie-web'
import * as animationData from './data/data.json'
import $ from 'jquery'
//import * as animationData0 from '../prozess/data/de/data0'

$(document).ready(function() {
  animationHandlerDerp()
})

function animationHandlerDerp() {
  var divContainer = document.getElementById('animation')
  if (divContainer !== null) {
    var params = {
      container: divContainer,
      renderer: 'svg',
      loop: false,
      autoplay: false,
      animationData: animationData.default,
    }

    var introSegment = [0, 140]
    var idleSegment = [141, 260]

    var animation = lottie.loadAnimation(params)
    var scrollPos = document.documentElement.scrollTop
    var scrollPos2 = window.scrollY
    var screenHeight = document.documentElement.clientHeight
    var animHeight = divContainer.offsetHeight
    var animPos = divContainer.offsetTop
    var myInterval

    window.addEventListener('resize', getScreenHeight)
    animation.addEventListener('complete', playIdle)

    myInterval = setInterval(function scrolling() {
      scrollPos = document.documentElement.scrollTop
      scrollPos2 = window.scrollY
      if (
        animPos - screenHeight + animHeight * 2 <= scrollPos ||
        animPos - screenHeight + animHeight * 2 <= scrollPos2
      ) {
        animation.setSpeed(0.75)
        animation.playSegments(introSegment, true)
        clearInterval(myInterval)
      }
    }, 10)
  }
  function getScreenHeight() {
    screenHeight = document.documentElement.clientHeight
  }

  function playIdle() {
    animation.playSegments(idleSegment, true)
  }
}
