import lottie from 'lottie-web'
import $ from 'jquery'

/** german data */
import * as animationData0 from './data/de/data0.json'
import * as animationData1 from './data/de/data1.json'
import * as animationData2 from './data/de/data2.json'
import * as animationData3 from './data/de/data3.json'
import * as animationData4 from './data/de/data4.json'
import * as animationData5 from './data/de/data5.json'
import * as animationData6 from './data/de/data6.json'
import * as animationData7 from './data/de/data7.json'

/** english data */
import * as animationData8 from './data/en/data0.json'
import * as animationData9 from './data/en/data1.json'
import * as animationData10 from './data/en/data2.json'
import * as animationData11 from './data/en/data3.json'
import * as animationData12 from './data/en/data4.json'
import * as animationData13 from './data/en/data5.json'
import * as animationData14 from './data/en/data6.json'
import * as animationData15 from './data/en/data7.json'

$(document).ready(function() {
  animationHandlerProzess()
})

function animationHandlerProzess() {
  var divContainer = document.getElementById('anim')
  if (divContainer !== null) {
    var params = [
      {
        container: divContainer,
        renderer: 'svg',
        loop: false,
        autoplay: false,
        animationData: animationData0.default,
        rendererSettings: {
          preserveAspectRatio: 'xMinYMin',
        },
      },

      {
        container: divContainer,
        renderer: 'svg',
        loop: false,
        autoplay: false,
        animationData: animationData1.default,
        rendererSettings: {
          preserveAspectRatio: 'xMinYMin',
        },
      },

      {
        container: divContainer,
        renderer: 'svg',
        loop: false,
        autoplay: false,
        animationData: animationData2.default,
        rendererSettings: {
          preserveAspectRatio: 'xMinYMin',
        },
      },

      {
        container: divContainer,
        renderer: 'svg',
        loop: false,
        autoplay: false,
        animationData: animationData3.default,
        rendererSettings: {
          preserveAspectRatio: 'xMinYMin',
        },
      },

      {
        container: divContainer,
        renderer: 'svg',
        loop: false,
        autoplay: false,
        animationData: animationData4.default,
        rendererSettings: {
          preserveAspectRatio: 'xMinYMin',
        },
      },

      {
        container: divContainer,
        renderer: 'svg',
        loop: false,
        autoplay: false,
        animationData: animationData5.default,
        rendererSettings: {
          preserveAspectRatio: 'xMinYMin',
        },
      },

      {
        container: divContainer,
        renderer: 'svg',
        loop: false,
        autoplay: false,
        animationData: animationData6.default,
        rendererSettings: {
          preserveAspectRatio: 'xMinYMin',
        },
      },

      {
        container: divContainer,
        renderer: 'svg',
        loop: false,
        autoplay: false,
        animationData: animationData7.default,
        rendererSettings: {
          preserveAspectRatio: 'xMinYMin',
        },
      },

      {
        container: divContainer,
        renderer: 'svg',
        loop: false,
        autoplay: false,
        animationData: animationData8.default,
        rendererSettings: {
          preserveAspectRatio: 'xMinYMin',
        },
      },

      {
        container: divContainer,
        renderer: 'svg',
        loop: false,
        autoplay: false,
        animationData: animationData9.default,
        rendererSettings: {
          preserveAspectRatio: 'xMinYMin',
        },
      },

      {
        container: divContainer,
        renderer: 'svg',
        loop: false,
        autoplay: false,
        animationData: animationData10.default,
        rendererSettings: {
          preserveAspectRatio: 'xMinYMin',
        },
      },

      {
        container: divContainer,
        renderer: 'svg',
        loop: false,
        autoplay: false,
        animationData: animationData11.default,
        rendererSettings: {
          preserveAspectRatio: 'xMinYMin',
        },
      },

      {
        container: divContainer,
        renderer: 'svg',
        loop: false,
        autoplay: false,
        animationData: animationData12.default,
        rendererSettings: {
          preserveAspectRatio: 'xMinYMin',
        },
      },

      {
        container: divContainer,
        renderer: 'svg',
        loop: false,
        autoplay: false,
        animationData: animationData13.default,
        rendererSettings: {
          preserveAspectRatio: 'xMinYMin',
        },
      },

      {
        container: divContainer,
        renderer: 'svg',
        loop: false,
        autoplay: false,
        animationData: animationData14.default,
        rendererSettings: {
          preserveAspectRatio: 'xMinYMin',
        },
      },

      {
        container: divContainer,
        renderer: 'svg',
        loop: false,
        autoplay: false,
        animationData: animationData15.default,
        rendererSettings: {
          preserveAspectRatio: 'xMinYMin',
        },
      },
    ]
    var stagingSegment = [0, 20]
    var hoverSegmentIn = [40, 50]
    var hoverSegmentOut = [50, 40]

    var animation0,
      animation1,
      animation2,
      animation3,
      animation4,
      animation5,
      animation6,
      animation7

    var language = $('.dhsv_vc_prozess').attr('data-language')

    if (language === 'german') {
      animation0 = lottie.loadAnimation(params[0])
      animation1 = lottie.loadAnimation(params[1])
      animation2 = lottie.loadAnimation(params[2])
      animation3 = lottie.loadAnimation(params[3])
      animation4 = lottie.loadAnimation(params[4])
      animation5 = lottie.loadAnimation(params[5])
      animation6 = lottie.loadAnimation(params[6])
      animation7 = lottie.loadAnimation(params[7])
    } else {
      animation0 = lottie.loadAnimation(params[8])
      animation1 = lottie.loadAnimation(params[9])
      animation2 = lottie.loadAnimation(params[10])
      animation3 = lottie.loadAnimation(params[11])
      animation4 = lottie.loadAnimation(params[12])
      animation5 = lottie.loadAnimation(params[13])
      animation6 = lottie.loadAnimation(params[14])
      animation7 = lottie.loadAnimation(params[15])
    }

    var scrollPos = document.documentElement.scrollTop
    var scrollPos2 = window.scrollY
    var screenHeight = document.documentElement.clientHeight
    var animHeight = divContainer.offsetHeight
    var animPos = $('#anim').offset()
    var myInterval

    window.addEventListener('resize', getScreenHeight)

    myInterval = setInterval(function scrolling() {
      scrollPos = document.documentElement.scrollTop
      scrollPos2 = window.scrollY

      if (
        animPos['top'] - (screenHeight - animHeight) <= scrollPos ||
        animPos['top'] - (screenHeight - animHeight) <= scrollPos2
      ) {
        playAnim()
        clearInterval(myInterval)
      }
    }, 10)

    $(document).on(
      {
        mouseenter: function() {
          animIn0()
        },
        mouseleave: function() {
          animOut0()
        },
      },
      '#trigger0'
    )

    $(document).on(
      {
        mouseenter: function() {
          animIn1()
        },
        mouseleave: function() {
          animOut1()
        },
      },
      '#trigger1'
    )

    $(document).on(
      {
        mouseenter: function() {
          animIn2()
        },
        mouseleave: function() {
          animOut2()
        },
      },
      '#trigger2'
    )

    $(document).on(
      {
        mouseenter: function() {
          animIn3()
        },
        mouseleave: function() {
          animOut3()
        },
      },
      '#trigger3'
    )

    $(document).on(
      {
        mouseenter: function() {
          animIn4()
        },
        mouseleave: function() {
          animOut4()
        },
      },
      '#trigger4'
    )

    $(document).on(
      {
        mouseenter: function() {
          animIn5()
        },
        mouseleave: function() {
          animOut5()
        },
      },
      '#trigger5'
    )

    $(document).on(
      {
        mouseenter: function() {
          animIn6()
        },
        mouseleave: function() {
          animOut6()
        },
      },
      '#trigger6'
    )

    $(document).on(
      {
        mouseenter: function() {
          animIn7()
        },
        mouseleave: function() {
          animOut7()
        },
      },
      '#trigger7'
    )
  }

  function getScreenHeight() {
    screenHeight = document.documentElement.clientHeight
  }

  function playAnim() {
    animation0.playSegments(stagingSegment, true)

    setTimeout(function() {
      animation1.playSegments(stagingSegment, true)
    }, 100)
    setTimeout(function() {
      animation2.playSegments(stagingSegment, true)
    }, 200)
    setTimeout(function() {
      animation3.playSegments(stagingSegment, true)
    }, 300)
    setTimeout(function() {
      animation4.playSegments(stagingSegment, true)
    }, 400)
    setTimeout(function() {
      animation5.playSegments(stagingSegment, true)
    }, 500)
    setTimeout(function() {
      animation6.playSegments(stagingSegment, true)
    }, 600)
    setTimeout(function() {
      animation7.playSegments(stagingSegment, true)
    }, 700)
  }

  function animIn0() {
    animation0.playSegments(hoverSegmentIn, true)
  }

  function animOut0() {
    animation0.playSegments(hoverSegmentOut, true)
  }

  function animIn1() {
    animation1.playSegments(hoverSegmentIn, true)
  }

  function animOut1() {
    animation1.playSegments(hoverSegmentOut, true)
  }

  function animIn2() {
    animation2.playSegments(hoverSegmentIn, true)
  }

  function animOut2() {
    animation2.playSegments(hoverSegmentOut, true)
  }

  function animIn3() {
    animation3.playSegments(hoverSegmentIn, true)
  }

  function animOut3() {
    animation3.playSegments(hoverSegmentOut, true)
  }

  function animIn4() {
    animation4.playSegments(hoverSegmentIn, true)
  }

  function animOut4() {
    animation4.playSegments(hoverSegmentOut, true)
  }

  function animIn5() {
    animation5.playSegments(hoverSegmentIn, true)
  }

  function animOut5() {
    animation5.playSegments(hoverSegmentOut, true)
  }

  function animIn6() {
    animation6.playSegments(hoverSegmentIn, true)
  }

  function animOut6() {
    animation6.playSegments(hoverSegmentOut, true)
  }

  function animIn7() {
    animation7.playSegments(hoverSegmentIn, true)
  }

  function animOut7() {
    animation7.playSegments(hoverSegmentOut, true)
  }
}
