import $ from 'jquery'

function staffExpanded() {
  $('.staff-info').matchHeight()

  $('.staff-expand').on('click', function() {
    let $staff = $(this).closest('.staff')
    $staff
      .toggleClass('expanded')
      .find('.staff-info__bottom')
      .slideToggle(400)
  })
}

$(document).ready(() => {
  staffExpanded()
})
