import $ from 'jquery'

$(document).ready(function() {
  stage()
})

function stage() {
  var wrapp = $('.service-wrapp')
  var divContainer2 = $('#wrapper-anim-trigger')

  function scrollStage() {
    var scrollPos3 = $(window).scrollTop()
    var screenHeight = document.documentElement.clientHeight
    var animHeight = divContainer2[0].offsetHeight
    var animPos = divContainer2.offset().top

    if ($(window).width() < 768) {
      if (scrollPos3 > animPos - $(window).height() / 1.3) {
        wrapp.addClass('staged')
        wrapp.removeClass('unstaged')
      }
    } else {
      if (animPos - screenHeight + animHeight <= scrollPos3) {
        wrapp.addClass('staged')
        wrapp.removeClass('unstaged')
      }
    }
  }

  if (divContainer2.length > 0) {
    $(window).scroll(() => {
      scrollStage()
    })
  }
}
