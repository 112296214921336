import $ from 'jquery'

$(document).ready(function() {
  dhsv_vc_jobs_accordion()
})

function dhsv_vc_jobs_accordion() {
  $('.dhsv_vc_jobs_accordion').each(function() {
    var allPanels = $(this)
      .find('.jobs_accordion dd')
      .hide()

    $('.jobs_accordion > dt > a').click(function(e) {
      e.preventDefault()
      var target = $(this)
        .parent()
        .next()

      if (!target.hasClass('active')) {
        allPanels.removeClass('active').slideUp()
        target.addClass('active').slideDown()
      } else {
        allPanels.removeClass('active').slideUp()
      }
    })
  })
}
