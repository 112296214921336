import lottie from 'lottie-web'
import $ from 'jquery'
import 'waypoints/lib/jquery.waypoints.min.js'
//import * as animationData0 from '../prozess/data/de/data0'

var iconAnimations = []
var divContainers = document.getElementsByClassName('anim_container')

Array.from(divContainers).forEach(el => {
  var params = {
    container: el,
    renderer: 'svg',
    loop: false,
    autoplay: false,
    animationData: require(`./data/${el.dataset.animation}`),
  }

  iconAnimations.push(lottie.loadAnimation(params))
})

$(window).load(function() {
  var introSegment = [0, 38]
  var idleSegment = [39, 119]
  iconAnimations.forEach(function(animation) {
    animation.setSpeed(0.75)
    $(animation.wrapper).waypoint({
      handler: direction => {
        if (direction == 'down') {
          animation.playSegments(introSegment, true)
        }
      },
      offset: 'bottom-in-view',
    })

    animation.addEventListener('complete', playIdle)

    function playIdle() {
      animation.playSegments(idleSegment, true)
    }
  })
})
