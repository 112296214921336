// import external dependencies
import 'jquery'

// import everything from autoload
import './autoload/_bootstrap.js' // eslint-disable-line

// import vc-elements javascript files
import '../vc_elements/_new/main.js'; import '../vc_elements/animation/main.js'; import '../vc_elements/button/main.js'; import '../vc_elements/case_studies_filter/main.js'; import '../vc_elements/counter/main.js'; import '../vc_elements/icon_animation/main.js'; import '../vc_elements/jobs_accordion/main.js'; import '../vc_elements/prozess/main.js'; import '../vc_elements/services/main.js'; import '../vc_elements/staff_card/main.js'; import '../vc_elements/template_element/main.js'; import '../vc_elements/text_with_gallery/main.js'; import '../vc_elements/vertrieb/main.js' // eslint-disable-line

// import react habitat and react components
import './init/react'

// import local dependencies
import Router from './util/Router'
import common from './routes/common'
import home from './routes/home'
import aboutUs from './routes/about'

/** Populate Router instance with DOM routes */
const routes = new Router({
  // All pages
  common,
  // Home page
  home,
  // About Us page, note the change from about-us to aboutUs.
  aboutUs,
})

// Load Events
jQuery(document).ready(() => routes.loadEvents())
