import 'jquery-match-height'
import '@fancyapps/fancybox'
import AOS from 'aos'

export default {
  init() {
    // JavaScript to be fired on all pages
    AOS.init({
      easing: 'ease-out-back',
      duration: 1000,
    })
    // Add padding top under header
    var bumpIt = function() {
        $('body.home').css('padding-top', $('.banner').outerHeight())
      },
      didResize = true

    $(window).resize(function() {
      didResize = true
    })
    setInterval(function() {
      if (didResize) {
        didResize = false
        bumpIt()
      }
    }, 250)

    function throttled(delay, fn) {
      let lastCall = 0
      return function(...args) {
        const now = new Date().getTime()
        if (now - lastCall < delay) {
          return
        }
        lastCall = now
        return fn(...args)
      }
    }

    // CALL FUNCTIONS
    init_sticky_animation()

    // DECLARE FUNCTIONS
    function init_sticky_animation() {
      var lastScrollTop = 0
      var scrollTop = undefined
      var body = $('body')
      var distance = 0

      function handleScroll() {
        scrollTop = window.pageYOffset

        if (scrollTop <= distance) {
          body.removeClass('sticky-nav')
        } else {
          body.addClass('sticky-nav')
        }

        lastScrollTop
      }

      handleScroll()
      $(window).on('scroll', throttled(10, handleScroll))
    }

    $('#scroll-top').on('click', function() {
      $('html, body').animate(
        {
          scrollTop: 0,
        },
        600
      )
    })

    $('a[href^="#popup-"]').on('click', function(e) {
      e.preventDefault()
      let popup = $(this.hash)
      if (popup.length) {
        $(document).mouseup(function(e) {
          if (
            !popup.find('.vc_row').is(e.target) &&
            popup.find('.vc_row').has(e.target).length === 0
          ) {
            popup
              .fadeOut(400)
              .find('.popup-close-btn')
              .remove()
          }
        })
        // console.log(popup.children('.vc_row')) //eslint-disable-line
        popup
          .children('.vc_row')
          .prepend(
            '<div class="popup-close-btn"><i class="ion-ios-close-empty"></i></div>'
          )
          .end()
          .on('click', '.popup-close-btn', function() {
            popup
              .fadeOut(400)
              .find('.popup-close-btn')
              .remove()
          })
          .fadeIn(400)
      }
    })

    let fancyBlock = $('.fancybox, .prettyphoto')
    if (!fancyBlock.find('.image-link__zoom').length) {
      fancyBlock.prepend('<div class="image-link__zoom">VERGRÖSSERN</div>')
    }
    fancyBlock.fancybox({
      touch: false,
      buttons: ['thumbs', 'close'],
      minHeight: 0,
      helpers: {
        overlay: {
          locked: false,
        },
      },
    })

    $('#mobile_nav')
      .on('hide.bs.collapse', function() {
        $('body').removeClass('nav-open')
        if ($('.nav-overlay').length) {
          $('.nav-overlay').remove()
        }
      })
      .on('show.bs.collapse', function() {
        $('body').addClass('nav-open')
        $('body').append("<div class='nav-overlay'>Тест</div>")
      })

    let pageHeader = $('.page-header')
    let headerBanner = $('header.banner')
    function setPageHeaderPadding() {
      pageHeader.css('padding-top', headerBanner.outerHeight() + 55)
    }
    if (pageHeader.length) {
      setPageHeaderPadding()
      $(window).on('resize', function() {
        setPageHeaderPadding()
      })
    }

    $('.banner-top').on('click', '.search-submit', function() {
      let searchForm = $(this).closest('.search-form')
      if (!searchForm.hasClass('expanded')) {
        searchForm.addClass('expanded')
        $(document).mouseup(function(e) {
          if (
            !searchForm.is(e.target) &&
            searchForm.has(e.target).length === 0 &&
            !searchForm.find('.search-field').val()
          ) {
            searchForm.removeClass('expanded')
          }
        })
        return false
      }
    })

    $('.mobile-menu').on('click', '.mobile-search-btn', function() {
      let searchWrap = $(this)
        .closest('.mobile-menu')
        .find('.search-form-wrapper')
      if (!searchWrap.hasClass('slided')) {
        searchWrap
          .css('top', headerBanner.outerHeight())
          .slideDown(400)
          .addClass('slided')

        $(document).mouseup(function(e) {
          if (
            !searchWrap.is(e.target) &&
            searchWrap.has(e.target).length === 0
          ) {
            searchWrap.slideUp(400).removeClass('slided')
          }
        })
      }
    })

    // Init MatchHeight
    $('.matchHeight').matchHeight()

    function postsPosition() {
      let newsList = $('.news-list')
      var scrollTop = $(window).scrollTop()
      var windowHeight = $(window).height()
      var el = newsList.find('.news-list-item:last-child')
      var offsetTop = el.offset().top
      if (el.height() + offsetTop < scrollTop + windowHeight) {
        postsLoadMore()
      }
    }

    let loading = false
    function postsLoadMore() {
      if (
        !loading &&
        window.ajaxpost.max_pages != window.ajaxpost.current_page
      ) {
        loading = true
        var data = {
          action: 'loadmore',
          query: window.ajaxpost.true_posts,
          page: window.ajaxpost.current_page,
        }
        $('#loader-more').fadeIn(300)
        $.ajax({
          url: window.ajaxpost.ajaxurl,
          data: data,
          type: 'POST',
          dataType: 'html',
        })
          .done(function(data) {
            if (data) {
              window.ajaxpost.current_page++
              $('.news-list').append(data)
              loading = false
            }
          })
          .always(function() {
            $('#loader-more').fadeOut(100)
          })
      }
    }

    $(document).on('scroll', function() {
      if ($('.news-list').length) {
        postsPosition()
      }
    })

    $('.gform_drop_area')
      .on('dragenter dragover', function(e) {
        e.preventDefault()
        e.stopPropagation()
        $(this).addClass('dropped')
      })
      .on('drop dragleave ', function(e) {
        e.preventDefault()
        e.stopPropagation()
        $(this).removeClass('dropped')
      })

    // Full width Row
    function resizeFullWidthRow() {
      $('[data-vc-full-width="true"]').each(function() {
        $(this).css({
          width: document.body.clientWidth,
          marginLeft: -document.body.clientWidth / 2,
        })

        if (!$(this).attr('data-vc-stretch-content')) {
          let containerWidth = $('.container.wrap').outerWidth()
          let padding = (document.body.clientWidth - containerWidth) / 2
          $(this).css({
            paddingLeft: padding,
            paddingRight: padding,
          })
        }
      })
    }

    resizeFullWidthRow()
    $(window).on('resize', function() {
      resizeFullWidthRow()
    })
    // Remove placeholder
    function removePlaceholder() {
      $('input,textarea').each(function() {
        $(this).data('holder', $(this).attr('placeholder'))

        $(this).focusin(function() {
          $(this).attr('placeholder', '')
        })

        $(this).focusout(function() {
          $(this).attr('placeholder', $(this).data('holder'))
        })
      })
    }
    removePlaceholder()

    $(document).bind('gform_post_render', function() {
      removePlaceholder()
    })
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  },
}
