import lottie from 'lottie-web'
import $ from 'jquery'

/** german data */
import * as animationData0 from './data/de/data.json'

/** english data */
import * as animationData1 from './data/en/data.json'

$(document).ready(function() {
  animationHandlerVertrieb()
})

function animationHandlerVertrieb() {
  var divContainer = document.getElementById('vertrieb_anim')
  if (divContainer !== null) {
    var params = [
      {
        container: divContainer,
        renderer: 'svg',
        loop: true,
        autoplay: false,
        animationData: animationData0.default,
        rendererSettings: {
          preserveAspectRatio: 'xMinYMin',
        },
      },
      {
        container: divContainer,
        renderer: 'svg',
        loop: true,
        autoplay: false,
        animationData: animationData1.default,
        rendererSettings: {
          preserveAspectRatio: 'xMinYMin',
        },
      },
    ]

    var animation0

    var language = $('.dhsv_vc_vertrieb').attr('data-language')

    if (language === 'german') {
      animation0 = lottie.loadAnimation(params[0])
    } else {
      animation0 = lottie.loadAnimation(params[1])
    }

    var scrollPos = document.documentElement.scrollTop
    var scrollPos2 = window.scrollY
    var screenHeight = document.documentElement.clientHeight
    var animHeight = divContainer.offsetHeight
    var animPos = $('#vertrieb_anim').offset()
    var myInterval

    window.addEventListener('resize', getScreenHeight)

    myInterval = setInterval(function scrolling() {
      scrollPos = document.documentElement.scrollTop
      scrollPos2 = window.scrollY

      if (
        animPos['top'] - (screenHeight - animHeight) <= scrollPos ||
        animPos['top'] - (screenHeight - animHeight) <= scrollPos2
      ) {
        playAnim()
        clearInterval(myInterval)
      }
    }, 10)
  }
  function getScreenHeight() {
    screenHeight = document.documentElement.clientHeight
  }

  function playAnim() {
    animation0.play()
  }
}
