import $ from 'jquery'

function galleryInit() {
  $('.images-wrapper').each(function() {
    var galleryWrapper = $(this)
    var galleryMaxChange = galleryWrapper.data('max-change') * 1000
    galleryChange(galleryWrapper)
    setInterval(function() {
      // console.log($(this).find('.active')) //eslint-disable-line
      galleryChange(galleryWrapper)
    }, galleryMaxChange)
  })
}

function galleryChange(wrapper) {
  wrapper.find('.text-gallery_images').each(function() {
    var galleryChange = $(this).data('change') * 1000
    var activeGallery = $(this)
    setTimeout(function() {
      var activeImg = activeGallery.find('.active')
      var activeImgIndex = activeImg.index()
      var lastImgIndex = activeGallery.find('img:last-child').index()
      if (activeImgIndex == lastImgIndex) {
        activeImgIndex = -1
      }
      activeImg.removeClass('active')
      activeGallery
        .find('img')
        .eq(activeImgIndex + 1)
        .addClass('active')
    }, galleryChange)
  })
}

$(window).on('load', function() {
  galleryInit()
})
