import $ from 'jquery'
import Isotope from 'isotope-layout'
import 'isotope-packery'

function caseGridInit() {
  let $caseContainer = $('.case-studies__wrapp')
  if ($caseContainer.length) {
    var settings = {
      layoutMode: 'packery',
      itemSelector: '.case',
      percentPosition: true,
      packery: {
        gutter: '.gutter-sizer',
      },
    }
    var $grid

    setTimeout(function() {
      $grid = new Isotope('.case-studies__inner', settings)
    }, 250)
    // $grid.reLayout()
    $('.filter-button-group').on('click', 'button', function(e) {
      /*global ajax*/
      e.preventDefault()
      let link = $(this)
      let loading = false
      if (!loading) {
        loading = true
        if (!$('.ajax-overlay').length) {
          $caseContainer.append('<div class="ajax-overlay"></div>')
        }
        $('.ajax-overlay').show()
        let data = {
          action: 'filter_category',
          cat_id: link.data('filter'),
          nonce: ajax.nonce,
        }
        $.ajax({
          url: ajax.url,
          type: 'POST',
          data: data,
          success: function(resp) {
            $caseContainer.html(resp)
            // $grid.destroy()
            $grid = new Isotope('.case-studies__inner', settings)
            $('.filter-button').removeClass('active')
            link.addClass('active')
            loading = false
            $('.ajax-overlay').fadeOut()
          },
          error: function(err) {
            console.log(err.textStatus) //eslint-disable-line
          },
        })
      }
    })

    $('.cs-filter').on('click', '.load-more-case', function(e) {
      e.preventDefault()
      // console.log('test') //eslint-disable-line
      let loading = false
      let link = $(this)
      let currentPage = $(this).attr('data-current-page')
      console.log(currentPage) //eslint-disable-line
      let maxNumPage = $(this).attr('data-max-num-page')
      if (!loading && currentPage < maxNumPage) {
        loading = true
        if (!$('.ajax-overlay').length) {
          $caseContainer.append('<div class="ajax-overlay"></div>')
        }
        $('.ajax-overlay').show()
        let data = {
          action: 'more_case',
          cat_id: $('.filter-button.active').attr('data-filter'),
          current_page: currentPage,
          nonce: ajax.nonce,
        }
        $.ajax({
          url: ajax.url,
          type: 'POST',
          data: data,
          dataType: 'json',
          success: function(resp) {
            currentPage++
            link.attr('data-current-page', currentPage)
            if (currentPage >= maxNumPage) {
              link.closest('.more-case').hide()
            }
            var $cases = new Array()
            $.each(resp, function(index, value) {
              $cases[index] = $(value)[0]
            })
            $grid = new Isotope('.case-studies__inner', settings)
            $caseContainer.find('.case-studies__inner').append($cases)
            $('.ajax-overlay').fadeOut()
            $grid.appended($cases)
          },
          error: function(err) {
            console.log(err.textStatus) //eslint-disable-line
          },
        })
      }
    })
  }
}

$(document).on('load', caseGridInit())
